import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Flex, Label, Icon, Button, Card } from "@zeal/web-ui";
export default function EmailSentCard(props) {
  const { t } = useTranslation("login");
  const history = useHistory();
  const emailSubtitle = /* @__PURE__ */ React.createElement(React.Fragment, null, t("question"), /* @__PURE__ */ React.createElement("br", null), t("email_us"));
  const sentEmailText = /* @__PURE__ */ React.createElement(React.Fragment, null, t("email_msg"), /* @__PURE__ */ React.createElement("br", null), t("ur_password"));
  const backToLogin = useCallback(() => {
    history.push("/");
  }, [history]);
  return /* @__PURE__ */ React.createElement(
    Card,
    {
      p: "12",
      className: "rounded-xl grid w-full",
      styles: {
        minWidth: "400px"
      },
      shadow: "none"
    },
    /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "6" }, /* @__PURE__ */ React.createElement(Flex, { justify: "center", align: "center" }, /* @__PURE__ */ React.createElement(
      Icon,
      {
        iconName: "envelope",
        size: "7x",
        variant: "thin",
        colorVariant: "secondary"
      }
    )), /* @__PURE__ */ React.createElement(
      Flex,
      {
        gap: "2",
        justify: "center",
        align: "center",
        className: "text-center",
        isColumn: true,
        styles: {
          width: "489px"
        }
      },
      /* @__PURE__ */ React.createElement(Label.Mid700, null, t("check_email")),
      /* @__PURE__ */ React.createElement(Label.Mid400, { variant: "subdued" }, sentEmailText)
    ), /* @__PURE__ */ React.createElement("hr", null), /* @__PURE__ */ React.createElement(Flex, { align: "center", isColumn: true, gap: "1", className: "text-center" }, /* @__PURE__ */ React.createElement(Label, { size: "2xl", weight: "semiBold", className: "my-2" }, emailSubtitle), /* @__PURE__ */ React.createElement(Label, { variant: "primary", size: "md" }, "LOL@myzeal.app")), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: backToLogin,
        variant: "primary",
        className: "w-full",
        size: "lg"
      },
      t("back_to_login")
    ))
  );
}
