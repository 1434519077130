import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Icon, Label } from '@zeal/web-ui';

export type LanguageButtonProps = {
	label: string;
	value: string;
	onClick?: () => void;
	flag?: JSX.Element;
};

export default function LanguageButton(props: LanguageButtonProps) {
	const { label, value, onClick } = props;
	const { i18n } = useTranslation();
	const { changeLanguage, resolvedLanguage } = i18n;

	const handleLanguageChange = useCallback(() => {
		changeLanguage(value);
	}, [value, changeLanguage, resolvedLanguage]);

	const isActive = value == resolvedLanguage;

	const buttonVariant = isActive ? 'primary' : 'transparent';

	const buttonCSS = isActive ? { color: 'white' } : { color: 'black' };

	return (
		<Button
			onClick={() => {
				handleLanguageChange();
				onClick && onClick();
			}}
			variant={buttonVariant}
			className={
				isActive
					? 'px-3 py-2 hover:shadow-sm justify-start'
					: 'px-3 py-2 hover:shadow-sm hover:bg-gray-100 justify-start'
			}
		>
			<Flex isStretched={true} justify="between" align="center">
				<Flex gap="2" align="center">
					<Label CSS={buttonCSS} size="sm">
						{label}
					</Label>
				</Flex>
				<Icon color="white" iconName={'check'} variant={'solid'} />
			</Flex>
		</Button>
	);
}
